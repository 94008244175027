import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import Logo from '../../assets/images/dny.png'

const PriceContainer = styled.div`
  display: flex
  align-items: center
  gap: 8px
`

const LogoImage = styled.img`
  width: 24px
  height: 24px
`

export default function Price() {
  const [price, setPrice] = useState<string | null>(null)

  useEffect(() => {
    // Fetch Dynasty Coin price from CoinGecko
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=dynasty-coin&vs_currencies=usd')
      .then(response => response.json())
      .then(data => setPrice(data['dynasty-coin']?.usd.toFixed(3) || 'N/A'))
      .catch(() => setPrice('N/A'))
  }, [])

  return (
    <PriceContainer>
      <LogoImage src={Logo} alt="Dynasty Coin logo" />
      <Text fontSize="1rem">{price ? `$${price}` : 'Loading...'}</Text>
    </PriceContainer>
  )
}
